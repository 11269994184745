.about-page {
  min-height: 100vh;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-page .about-container div p {
  line-height: 1.5rem;
}

.about-page .light {
  font-size: 0.8rem;
}

.about-page ul li p {
  display: inline;
}

.about-page ul {
  list-style: inside;
}

.about-page li + li {
  margin-top: 5px;
}

.about-container {
  padding: 20px 0;
  width: 40vw;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.about-container img {
  width: 100%;
  /* height: 300px; */
}
.about-container p + p {
  margin-top: 10px;
}

/* .about-page h1,
.about-page h2,
.about-page h3,
.about-page p {
  text-align: center;
} */

/* .about-page h2 {
  font-size: 1.5rem;
} */

/* .about-page p {
  margin-top: 20px;
  font-size: 1.2rem;
} */
.about-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
}

.about-btn:hover {
  background-color: #0056b3;
}

.about-container h1,
.about-container h2 {
  margin-bottom: 20px;
}

.about-container p.secondary-color,
.about-container h3 {
  margin: 15px 0;
}

.about-page p {
  font-size: 1.1rem;
}

.about-bottom-container {
  min-height: 20vh;
  width: 100vw;
  background-color: var(--background-color);
  z-index: 1;
  opacity: 0.9;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-call-to-action {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  width: 30%;
  padding: 20px 0;
}

.about-call-to-action button {
  /* width: 30%; */
  padding: 10px 20px;
}

@media screen and (max-width: 1400px) {
  .about-container {
    width: 60vw;
  }

  .about-call-to-action {
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .about-container {
    width: 90vw;
  }

  .about-call-to-action {
    width: 90%;
  }
}
