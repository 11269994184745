:root {
  /* --background-color: #ffffff; */
  --primary-color: rgb(57, 224, 121);
  --secondary-color: rgb(115, 115, 115);
  --accent-color: #eeeeee;
  --border-color: #c9c6c6;
  --text-color: #141414;
  /* --navbar-width: 20vw; */
  /* --navbar-background-color: rgba(57, 224, 121, 0.05); */
}

/* @font-face {
  font-family: "Epilogue-Light";
  src: local("Epilogue-Light"),
    url("./assets/epilogue/Epilogue-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Epilogue-Regular";
  src: local("Epilogue-Regular"),
    url("./assets/epilogue/Epilogue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Epilogue-Medium";
  src: local("Epilogue-Medium"),
    url("./assets/epilogue/Epilogue-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Epilogue-SemiBold";
  src: local("Epilogue-Semibold"),
    url("./assets/epilogue/Epilogue-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Epilogue-Bold";
  src: local("Epilogue-Bold"),
    url("./assets/epilogue/Epilogue-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: "Lato";
  src: url("./assets/lato/Lato-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("./assets/lato/Lato-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("./assets/lato/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */
/* @font-face {
  font-family: "Lato";
  src: url("./assets/lato/Lato-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
} */
/* @font-face {
  font-family: "Lato";
  src: url("./assets/lato/Lato-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
} */
/* @font-face {
  font-family: "Lato";
  src: url("./assets/lato/Lato-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("./assets/lato/Lato-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("./assets/lato/Lato-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Lato";
  src: url("./assets/lato/Lato-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Lato";
  src: url("./assets/lato/Lato-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
} */
/* @font-face {
  font-family: "Lato";
  src: url("./assets/lato/Lato-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
} */
/* @font-face {
  font-family: "Lato";
  src: url("./assets/lato/Lato-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
} */
/* @font-face {
  font-family: "Lato";
  src: url("./assets/lato/Lato-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
} */


/* .app {
  background-color: var(--background-color);
} */

.content {
  margin-left: var(--navbar-width);
  max-width: 1400px;
}

/* p,
div {
  font-family: "Epilogue-Regular";
  color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Epilogue-Bold";
  color: var(--text-color);
}

button {
  font-family: "Epilogue-SemiBold";
  color: var(--text-color);
  background-color: var(--primary-color);
  border: none;
  padding: 10px;
  font-size: 1rem;
  border-radius: 30px;
}

input {
  border: 1px solid var(--accent-color);
}

a,
li,
input,
label {
  font-family: "Epilogue-Light";
}

input::placeholder {
  color: var(--secondary-color);
} */

.navbar {
  min-height: 100vh;
  /* width: var(--navbar-width); */
  position: fixed;
  left: 0;
  top: 0;
  padding: 2% 0;
  padding-left: calc(var(--navbar-width) * 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  /* background-color: rgba(65, 65, 65, 0.151); */
  background-color: var(--accent-color);
  /* box-shadow: 4px 0 10px rgb(172, 172, 172); */
  box-shadow: 4px 0 10px rgb(200, 200, 200);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.navbar input {
  width: inherit;
  padding: 10px;
  padding-left: 15%;
  border-radius: 10px;
  font-family: "Epilogue-SemiBold";
  background-color: var(--background-color);
  color: var(--secondary-color);
}

.navbar .search-container {
  position: relative;
  width: 100%;
}
.navbar .search-container svg {
  position: absolute;
  top: 10%;
  left: 5%;
  /* padding: 5px 1px; */
  /* padding: 10px; */
  /* padding: 10px; */
}

.navbar .nav-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.navbar .nav-list a {
  text-decoration: none;
  color: var(--text-color);
  /* color: white; */
  font-family: "Epilogue-SemiBold";
  padding: 5px;
  border-radius: 10px;
}

.logout-btn {
  padding: 8px 16px;
  background-color: #1e3c72;
  color: white;
  cursor: pointer;
  margin-left: 20px;
  border: 1px solid white;
  font-weight: bold;
}

.logout-btn:hover {
  background-color: white;
  color: #1e3c72;
}

.hamburger-icon {
  display: none; /* Hide the hamburger icon by default for larger screens */
  cursor: pointer;
  font-size: 30px;
  color: white;
}

.nav-list a.active-link {
  /* font-weight: bolder; */
  /* text-decoration: underline 3px; */
  /* background-color: var(--primary-color);
   */
  /* background-color: white; */

  background-color: var(--primary-color);
  font-family: "Epilogue-SemiBold";
}

.nav-list span {
  margin-left: 5px;
}

/* .navbar ul,
.navbar ol,
.navbar li {
  list-style-type: none;
} */

.navbar button {
  background-color: transparent;
  border: none;
  text-align: start;
  padding: 5px;
}

.btn {
  color: white;
  border-radius: 10px;
  padding: 10px;
  font-size: 1rem;
  border: none;
}

.normal-background {
  background-color: #1e3c72;
}

.success-background {
  background-color: #4caf50;
}

.cancel-background {
  background-color: #808080;
}

.danger {
  color: #ff5050;
}

.danger-background {
  background-color: #ff5050;
}

.disabled {
  background-color: #d3d3d3;
  color: black;
}

.form-border {
  border: 1px solid #ccc;
}

/* .secondary-color {
  color: var(--secondary-color);
}

.primary-color {
  color: var(--primary-color);
}

.text-color {
  color: var(--text-color);
}

.accent-color {
  color: var(--accent-color);
}
.border-color {
  color: var(--border-color);
}

.bold {
  font-family: "Epilogue-Bold";
}

.semi-bold {
  font-family: "Epilogue-SemiBold";
}

.light {
  font-family: "Epilogue-Light";
}

.regular {
  font-family: "Epilogue-Regular";
} */

button:hover {
  cursor: pointer;
}

.error {
  font-family: "Epilogue-SemiBold";
  color: red;
  font-size: 0.8rem;
}

.secondary-color-button {
  color: var(--text-color);
  background-color: var(--accent-color);
}

/* 
@media screen and (max-width: 1200px) {
  .navbar {
    padding: 10px;
  }

} */

.custom-list-container ol {
  list-style: decimal !important; /* Force list style to decimal */
  padding-left: 20px !important; /* Force indentation */
}

.custom-list-container ul {
  list-style: disc !important; /* Force unordered list style */
  padding-left: 20px !important;
}

@media screen and (max-width: 1000px) {
  .navbar {
    justify-content: space-between;
    /* padding: 10px; */
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3; /* Ensure the navbar is on top */
    height: 7vh;
  }

  .content {
    margin-top: 7vh;
    min-height: 93vh;
  }

  .hamburger-icon {
    display: block;
    position: relative;
    z-index: 4; /* Ensure the hamburger icon is on top of the list items */
  }

  .nav-list {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: #1e3b7279;
    z-index: 2;
    align-items: center;
    gap: 10px;

    /* justify-self: center; */
  }

  .nav-list.show {
    display: flex;
  }

  .nav-list li {
    background-color: #1e3b7279;
    /* margin-bottom:2px; */
    font-weight: bold;
    width: 80%;
  }
  .nav-list li span {
    margin-left: 10px;
  }
  .logout-btn {
    width: 50%;
    background-color: #007bff;
    border-radius: 10px;
    /* margin:0 auto; */
    /* margin:0 auto; */
  }
}
