.add-goal-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.add-goal-form div {
  width: 70%;
}

.add-goal-form div p {
  color: var(--text-color);
  font-family: "Epilogue-SemiBold";
  margin-bottom: 5px;
}

.add-goal-form div input,
.add-goal-form div select {
  width: 100%;
  height: 40px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  font-family: "Epilogue-Regular";
  padding: 5px;
}

.add-goal-form option {
  font-family: "Epilogue-Regular";
  color: var(--text-color);
}

.add-goal-form .shared-checkbox-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.add-goal-form .shared-checkbox-container p {
  margin: 0;
}
.add-goal-form div .shared-checkbox-container p {
  font-family: "Epilogue-Light";
}

.add-goal-form .shared-checkbox-container input {
  height: fit-content;
  width: fit-content;
}

.add-goal-form .friends-list {
  max-height: 20vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
