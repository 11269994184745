.auth-header {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-color);
  border-bottom: 1px solid rgb(216, 216, 216);
  box-shadow: 0 3px 6px #d6d6d6;
  padding: 15px 20px;
  min-height: 5vh;
  width: 100vw;
}

.auth-header-buttons-container {
  display: flex;
  gap: 20px;
}
.auth-header-buttons-container button {
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  font-family: "Epilogue-SemiBold";
}
