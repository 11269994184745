.journal-post {
  position: relative;
  border: 1px solid #ccc;
  margin-top: 10px;
  padding: 10px;
}

.journal-post .journal-icon {
  position: absolute;
  top: 0;
  left: 3%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: black;
  background-color: white;
}

.journal-post .journal-category {
  font-size: 0.8rem;
}
.journal-post .journal-title {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}
.journal-post .journal-content {
  padding: 10px;
}

.journal-post p {
  display: block;
}

.preview.journal-post::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.8)
  );
}
