.milestone-goal-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid black;
  width: 100%;
}

.goal-container .goal-container {
  border: none;
}

/* .goal {
  width: 100%;
} */
.viewTasks-button {
  border: none;
  /* border-top: 1px solid black; */

  background-color: transparent;
  color: var(--primary-color);
  font-weight: bold;
  text-align: left;
  margin-left: 5px;
  /* border: none; */
}

/* .notes-container {
  height: 100%;
  width: 100%;
  background-color: #007bff;
  border-radius: 0 15px 15px 0;
} */

/* .notes-container button {
  background-color: transparent;
  color: white;
  font-size: 1.1rem;
  height: 100%;
  width: 100%;
} */

.add-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.tasks {
  border: none;
  /* border-top: 1px solid black; */

  display: flex;
  flex-direction: column;
  align-items: center;
}

.task {
  display: flex;
  width: 100%;
  border: none;
  background-color: #1e3b7205;
  padding: 10px;
  justify-content: space-around;
  /* border: 1px solid gray; */
}

.task-title {
  flex-basis: 80%;
}

.task-title.done {
  text-decoration: line-through;
  color: var(--border-color);
}
.task-buttons {
  display: flex;
  align-items: center;
}
.task-buttons button {
  font-size: 20px;
  margin-left: 7px;
  height: max-content;
  border: none;
  background-color: transparent;
}
/* #goal-title {
  text-align: center;
  font-size: 1.3rem;
  border-bottom: 1px solid black;
  padding-bottom: 20px;
  margin-bottom: 2px;
} */

.add-task {
  display: flex;
  align-items: center;
}

.add-task input {
  flex: 0.7;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}
