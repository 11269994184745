/* CSS for Navigation Bar */
.profile {
  width: 100%;
  max-width: 800px;
  padding-top: 20px;
  margin: 0 auto;
}

.profile-nav {
  background-color: #335eab; /* Background color of the navbar */
  /* display: flex; */
  width: inherit;
  margin-bottom: 10px;
  margin-top: 10px;
}

.profile-nav ul {
  display: flex;
  /* justify-content: space-between; when posts and analytics are added back, set it to space-between */
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

/* .profile-nav li {
    float:right;
} */

.profile-nav li button {
  display: block;
  font-size: 1.1rem;
  color: white; /* Text color of the buttons */
  text-align: center;
  padding: 14px 16px; /* Adjust padding as needed */
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/* .profile-nav li button:hover {
  background-color: #555;
} */

/* #username {
  text-align: center;
  font-size: 1.3rem;
  margin: 5px 0;
} */

.friendship {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}

.friendship button {
  background-color: transparent;
  color: red;
}
