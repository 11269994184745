.analytics {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.analytics > div {
  flex-basis: calc(30%);
  padding: 30px;
  /* width: 300px; */
  border: 1px solid #ccc;
  background-color: white;
}

@media screen and (max-width: 1000px) {
  .analytics {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
  }

  .analytics > div {
    width: 100%;
    max-width: 500px;
  }
}
