.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15vh;
  /* margin-top: 7vh; */
  /* height: 100vh; */
  /* background: linear-gradient(45deg, #1e3c72 25%, #2a5298 50%, #1e3c72 75%); */
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  max-width: 400px;
  width: 100%;
  gap: 10px;
}

.login-form h1 {
  margin-bottom: 10%;
}

.login-form input[type="email"],
.login-form input[type="password"] {
  width: 100%;
  padding: 15px;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  margin-bottom: 10px;
}

.login-form button {
  width: 300px;
}

@media screen and (max-width: 500px) {
  .login-form {
    width: 90vw;
  }
  .login-form button {
    width: 80%;
  }
}
