/* MainHub.css */
.main-hub-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.main-hub-container h1 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.main-hub-container p {
  font-size: 18px;
  color: #555;
  margin-bottom: 20px;
}

.section-grid {
  display: flex;
  flex-wrap: wrap;
}

.section {
  flex: 1;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  text-align: center;
}

.section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.section p {
  font-size: 16px;
  color: #555;
}
