.goal-post {
  position: relative;
  border: 1px solid #ccc;
  margin-top: 10px;
  padding: 10px;
}
.goal-post .goal-icon {
  position: absolute;
  top: 0;
  left: 3%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: green;
  background-color: white;
}

.goal-post .goal-shared {
  font-size: 0.7rem;
}

.goal-post .title {
  text-align: center;
  font-size: 1.5rem;
}

.goal-post .goal-type {
  font-style: italic;
}

.goal-post .goal-dates {
  /* font-style: italic; */
  text-align: center;
  font-size: 0.8rem;
}

.goal-post p {
  display: block;
}

.goal-post-task {
  font-size: 1rem;
}

.task-done {
  text-decoration: line-through;
}
