.search {
  padding-top: 20px;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}
.search form {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  margin: 0 10px;
}

.search input {
  width: 100%;
  /* max-width: 400px; */
  margin-right: 10px;
}

.search p {
  text-decoration: none;
}

.users-list p {
  text-align: center;
  font-size: 1.2rem;
}

.users-list li {
  border: 1px solid #ccc;
  padding: 5px 0;
}
.users-list li + li {
  margin-top: 5px;
}

.users-list a {
  text-decoration: none;
  color: #1e3c72;
}
