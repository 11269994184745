

.register-website-description-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.register-website-description {
  max-width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 90vh; */
  background-color: white;
  padding-top: 5vh;
  /* margin-top: 7vh; */
}
.register-content {
  width: fit-content;
  justify-content: center;
  align-items: center;

  display: flex;
  flex-direction: column;
  gap: 40px;
}

.register-content h1,
.register-content p {
  text-align: center;
}

.register-form {
  width: 90%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-form h1 {
  margin-bottom: 20px;
}

.register-form .form-group {
  width: 100%;
}

.register-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.register-form input[type="email"],
.register-form input[type="password"],
.register-form input[type="text"] {
  width: 100%;
  padding: 15px;

  border-radius: 10px;
  margin-bottom: 15px;
  box-sizing: border-box;
}

.register-form button {
  margin-top: 15px;
  margin-bottom: 10px;
  border: none;
  padding: 10px 0;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
}

.register-form a {
  text-decoration: none;
}

.register-container img {
  max-width: 100%;
  height: 30vh;
  margin-bottom: 10px;
}

@media screen and (max-width: 755px) {
  .register-website-description-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .register-website-description {
    max-width: 90%;
  }
}

@media screen and (max-height: 740px) {
  .register-container {
    padding-top: 1vh;
  }
}
