.habit-post {
  position: relative;
  border: 1px solid #ccc;
  margin-top: 10px;
  padding: 10px;
}

.habit-post .habit-icon {
  position: absolute;
  top: 0;
  left: 3%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: black;
  background-color: white;
}

.habit-post p {
  display: block;
}
