.habit-tracking-container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding-top: 20px;
}

.habit-tracking-container form {
  background-color: white;
  width: 100%;
  gap: 5px;
  padding: 10px;
  margin-bottom: 20px;
}

.habit-tracking-container form button {
  display: block;
  margin: 5px auto;
}

#form-title {
  font-size: 1.3rem;
  text-align: center;
}
.habit-tracking-container form input {
  width: 90%;
  height: 20px;
  margin-left: 10px;
}

.habit-tracking-container form select {
  width: 20%;
  margin-left: 10px;
  display: block;
}

.habit-tracking-container form label {
  font-weight: bold;
}

.frequency {
  color: #1e3c72;
  font-size: 0.9rem;
  font-weight: bold;
}

.habits {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 20px;
}

.habit {
  padding: 5px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle gray box shadow */
}

.habit-title {
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 10px;
}

.streaks {
  text-align: center;
  font-size: 0.9rem;
}

.habit-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.habits .btn {
  background-color: transparent;
  color: #1e3c72;
}
