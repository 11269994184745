.home-container {
  margin: 0 5% 3% 2%;
}

.home-container .home-header {
  display: flex;
  justify-content: space-between;
}

.home-container .secondary-color-button {
  padding: 10px 30px;
}

.home-container .members-count {
  margin: 30px 0;
}

.home-container .members-count p {
  font-size: 1rem;
}

.home-container .members-count p + p {
  margin-top: 5px;
}

.home-container .home-sections {
  border-bottom: 1px solid var(--border-color);
}

.home-container .home-sections button {
  border: none;
  border-radius: 0;
  border-bottom: 4px solid var(--accent-color);
  background-color: transparent;
}

.home-container .home-sections button + button {
  margin-left: 20px;
}

.home-container .community-filters {
  display: flex;
  gap: 10px;
  margin: 20px 0;
}

.home-container .home-post-form {
  border: 1px solid var(--border-color);
  padding: 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.home-container .home-post-form .post-input-container {
  display: flex;
  align-items: flex-start;
}

.home-container .home-post-form textarea {
  border: none;
  width: 100%;
  height: 60px;
  font-size: 1rem;
  padding: 10px;
  resize: none;
  overflow-y: auto;
}
.home-container .home-post-form textarea:focus {
  outline: none;
}

.home-container .home-post-form textarea::placeholder {
  color: var(--border-color);
  font-size: 1rem;
  font-family: "Epilogue-Regular";
}

.home-container .home-post-form .home-post-form-actions {
  display: flex;
  justify-content: flex-end;
}

.home-container .home-post-form .home-post-form-actions button {
  padding: 10px 25px;
  border-radius: 15px;
}

.home-container .home-sections + .home-post-form {
  margin-top: 20px;
}

.home-container .post-list {
  margin: 55px 0;
}
