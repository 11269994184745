.goal-tracking-container {
  margin: 0 5% 3% 2%;
}

.goal-tracking-container .goal-tracking-header {
  display: flex;
  justify-content: space-between;
}

.goal-tracking-container .goal-tracking-actions {
  display: flex;
  gap: 10px;
}

.goal-tracking-container .secondary-color-button {
  padding: 10px 30px;
}
.goal-tracking-container .secondary-color-button.chosen-category {
  font-family: "Epilogue-Bold";
  background-color: var(--border-color);
}

.goal-tracking-container .goal-status {
  width: 100%;
  background-color: var(--accent-color);
  border-radius: 20px;
  display: flex;
  padding: 2px;
  margin-bottom: 50px;
}

.goal-tracking-container .goal-status button {
  flex: 1;
  width: 100%;
  padding: 10px 0;
}

.goal-tracking-container .goal-status button + button {
  margin-left: -3%;
}

.goal-tracking-container .goal-status .selected-goal-status {
  background-color: var(--background-color);
  z-index: 1;
  transition: background-color 0.5s;
}

.goal-tracking-container .goal-categories {
  display: flex;
  gap: 10px;
  margin: 30px 0;
}

.goal-tracking-container .goal-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 90%;
  margin: auto;
}
