.counting-goal-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid black;
  /* border-bottom: 1px solid black; */
  /* border-radius: 15px; */
  /* width:90%; */
  width: 100%;
  /* max-width: 1500px; */
}

.counting-goal-container .goal-container {
  border: none;
}

.counting-goal-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  justify-content: center;
}

.counting-goal-details > div input {
  flex: 0.7;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.counting-goal-details button {
  /* color: white; */
  border-radius: 10px;
  /* padding: 10px; */
  font-size: 1rem;
  border: none;
  width: 100px;
  height: 30px;
  padding:0 !important;
}
