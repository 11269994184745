.friends-navbar {
  display: flex;
  justify-content: space-around;
  background-color: #0a1d4156;
  width: 100%;
  padding: 10px 0;
  margin: 0 auto;
}

.friends-navbar button {
  font-size: 1.1rem;

  cursor: pointer;
  outline: none;
  border: none;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  color: white;
  background-color: transparent;
}

.friend {
  height: 40px;
}

.friend button {
  border: 1px solid black;
  width: 100%;
  height: 100%;
  background-color: white;
  font-size: 1rem;
}

.friends-navbar .active {
  color: black;
  font-size: 1.3rem;
  font-weight: 500;
}
