.post-comments {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .infinite-scroll-component__outerdiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.comments {
  width: 98%;
  /* max-width: 1500px; */

  /* justify-content: center; */
}

.comment {
  width: 100%;
  align-self: center;
  border-left: 5px solid #ccc;
  background-color: white;
  padding: 10px;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comment button {
  background-color: transparent;
  border: 0;
  /* padding:0; */
  font-size: 1.2rem;
  width: 0;
  margin: 0 auto;
}

.comment button:hover {
  background-color: transparent;
  /* padding: 0; */
  border: 0;
}

.comment-author {
  font-weight: bold;
  margin-right: 5px;
  margin-bottom: 2px;
}

.comment-content {
  color: #555;
  font-size: 14px;
  /* margin-left: 10px; */
}

.post-comment-input {
  width: 95%;
  display: flex;
  align-items: center;
}

.post-comment-input input {
  flex: 0.7;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.post-comment-input button {
  flex: 0.3;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;

  transition: background-color 0.2s ease;
}

.post-comment-input button:hover {
  background-color: #0056b3;
}

.post-comments form {
  display: flex;
  margin-bottom: 20px;
  border-radius: 15px;
  background-color: transparent;
  height: 40px;
}

.post-comments form input {
  border-radius: 15px 0 0 15px !important; /* Apply border-radius to input fields and buttons */
  /* Add any other styles you want for input fields and buttons here */
  /* width: 80%; */
  height: 100%;
  margin: 0;
}
.post-comments form button {
  border-radius: 0 15px 15px 0; /* Apply border-radius to input fields and buttons */
  /* width: 20%; */
  background-color: #1e3c72;
  color: white;
  height: 100%;
}
