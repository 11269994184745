@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Raleway";
  src: url("./assets/raleway/Raleway-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url("./assets/raleway/Raleway-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Raleway";
  src: url("./assets/raleway/Raleway-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url("./assets/raleway/Raleway-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Raleway";
  src: url("./assets/raleway/Raleway-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* @font-face {
  font-family: "Allura";
  src: url("./assets/allura/Allura-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */
@font-face {
  font-family: "Raleway";
  src: url("./assets/raleway/Raleway-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Raleway";
  src: url("./assets/raleway/Raleway-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url("./assets/raleway/Raleway-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Raleway";
  src: url("./assets/raleway/Raleway-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url("./assets/raleway/Raleway-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Raleway";
  src: url("./assets/raleway/Raleway-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "dancing-script";
  src: url("./assets/dancing-script/DancingScript-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url("./assets/raleway/Raleway-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Raleway";
  src: url("./assets/raleway/Raleway-ExtraBold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url("./assets/raleway/Raleway-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

::-webkit-scrollbar {
  width: 10px;
  height: 16px; 
}

::-webkit-scrollbar-thumb {
  background-color: #999999; 
  border-radius: 999px; 
}

::-webkit-scrollbar-track {
  background: #ffffff; 
  border-radius: 999px; 
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Raleway";
  /* margin: 0; */
  /* 
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.quill .ql-toolbar {
  border: 2px solid #d1d1d1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.quill .ql-container {
  border: 2px solid #d1d1d1;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  min-height: 100px;
}
