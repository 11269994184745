.goal-container {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color);
  background-color: white;
  border-radius: 10px;
  width: 100%;
  
  /* max-width: 1500px; */
  padding: 15px;
}

.goal {
  width: 100%;
}
.viewTasks-button {
  background-color: transparent;
  color: darkblue;
  font-weight: bold;
  text-align: left;
}

.notes-container {
  /* height: 100%;
    width: 100%; */
  /* border-radius: 0 15px 15px 0; */
}

.add-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.tasks {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.task {
  display: flex;
  width: 100%;
  border: 1px solid gray;
}

.task-buttons {
  display: flex;
  padding: 10px;
}

.goal-title {
  text-align: center;
  font-size: 1.3rem;
  /* border-bottom: 1px solid black; */
  /* padding-bottom:20px; */
  /* margin-bottom: 2px; */
}

.deadline {
  text-align: center;
  font-size: 0.8rem;
}

.goal-actions {
  display: flex;
  justify-content: space-between;
}
.goal-actions button {
  border: none;
  background-color: transparent;
  /* color: white; */
  font-size: 1.1rem;
}
